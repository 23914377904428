<template>
  <div class="d-flex flex-column flex-center flex-column-fluid p-10">
    <!--begin::Illustration-->
    <img
      src="media/illustrations/sketchy-1/18.png"
      alt=""
      class="mw-100 mb-10 h-lg-450px"
    />
    <!--end::Illustration-->

    <!--begin::Message-->
    <h1 class="fw-bold mb-10" style="color: #a3a3c7">{{$t("Oups...")}}</h1>
    <!--end::Message-->

    <!--begin::Link-->
    <router-link to="/crafted/pages/profile/overview" class="btn btn-primary">
      {{$t("Retourner à l'accueil")}}
    </router-link>
    <!--end::Link-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n()


    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return{
      t
    }
  },
});
</script>
